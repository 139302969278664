<template>
  <v-breadcrumbs class="px-0" :items="breadcrumbItems"></v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    baseRoute: {
      type: String,
      required: true,
      default: "/",
    },
    spliceNumber: Number,
  },
  computed: {
    breadcrumbItems() {
      const path = this.$route.fullPath;
      const pathArray = path.split("/");
      const pathSliced = this.spliceNumber
        ? pathArray.splice(this.spliceNumber)
        : pathArray.splice(2);
      let pathReconstruct = this.baseRoute;

      let breadcrumbItems = [];
      pathSliced.forEach((path) => {
        let count = 1;
        let pathName = path.split("-").join(" ");
        pathName = path.split("?")[0];
        pathReconstruct += `/${path}`;
        const format = {
          text: pathName.charAt(0).toUpperCase() + pathName.slice(1),
          disabled: count == pathArray.length,
          to: pathReconstruct,
          exact: true,
        };
        count++;
        breadcrumbItems.push(format);
      });

      return breadcrumbItems;
    },
  },
};
</script>

<style></style>
